<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{$t('dealer_management.dealer_sales_manage')}}</h4>
          </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.fiscalYear')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalYearList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="pre_division_id"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.division')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.pre_division_id"
                          :options="divisionList"
                          id="pre_division_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="pre_district_id"
                      >
                        <template v-slot:label>
                          {{$t('org_pro_district.district')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.pre_district_id"
                          :options="districtList"
                          id="pre_district_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="pre_upazilla_id"
                      >
                        <template v-slot:label>
                          {{$t('org_pro_upazilla.upazilla')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.pre_upazilla_id"
                          :options="upazilaList"
                          id="pre_upazilla_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="dealer_id"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerConfig.dealer_name')}}<span class="text-danger"></span>
                        </template>
                        <b-form-select
                          plain
                          v-model="search.dealer_id"
                          :options="dealerNameList"
                          id="dealer_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="invoice_no"
                      >
                        <template v-slot:label>
                          {{$t('externalUserIrrigation.invoice_no')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          v-model="search.invoice_no"
                          id="invoice_no"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="dealer_code"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerReport.dealer_code')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          v-model="search.dealer_code"
                          id="dealer_code"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="sell_date_from"
                      >
                        <template v-slot:label>
                          {{$t('dealer_management.sell_date_from')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          class="date-picker"
                          v-model="search.sell_date_from"
                          id="sell_date_from"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="sell_date_to"
                      >
                        <template v-slot:label>
                          {{$t('dealer_management.sell_date_to')}}  <span class="text-danger"></span>
                        </template>
                        <b-form-input
                          class="date-picker"
                          v-model="search.sell_date_to"
                          id="sell_date_to"
                          >
                          </b-form-input>
                      </b-form-group>
                  </b-col>
                  <b-col class="col-sm-2 col-lg-6">
                      <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('externalUserIrrigation.sales_entry_list')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(sell_date)="data">
                                          {{ data.item.sell_date | dateFormat }}
                                        </template>
                                        <template v-slot:cell(fertilizer_qty)="data">
                                          {{ $n(data.item.fertilizer_qty, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(dealer_code)="data">
                                          {{ $n(data.item.dealer_code, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(fertilizer_amount)="data">
                                          {{ $n(data.item.fertilizer_amount, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" v-b-modal.modal-5 class="btn_table_action table_action_view" @click="details(data.item)" title="view"><i class="fas fa-eye"></i></a>
                                           </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('dealer_management.d_seles_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :id="editItemId" :items="items"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { saleEntryList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                invoice_no: '',
                fiscal_year_id: 0,
                pre_division_id: 0,
                pre_district_id: 0,
                pre_upazilla_id: 0,
                dealer_id: 0,
                dealer_code: '',
                sell_date_from: '',
                sell_date_to: '',
                status: 2
                // fertilizer_id: 0
            },
            upazilaList: [],
            districtList: [],
            dealerNameList: [],
            items: ''
        }
    },
    computed: {
      divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      },
        fiscalYearList: function () {
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        },
        fertilizerNameList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        },
        formTitle () {
            return this.editItemId === 0 ? this.$t('externalUserIrrigation.sales_entry') + ' ' + this.$t('globalTrans.entry') : this.$t('externalUserIrrigation.sales_entry') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('dealer_management.dealer_name'), class: 'text-center' },
                { label: this.$t('fertilizerReport.dealer_code'), class: 'text-center' },
                { label: this.$t('dealer_management.d_district'), class: 'text-center' },
                { label: this.$t('dealer_management.d_upizalla'), class: 'text-center' },
                { label: this.$t('externalUserIrrigation.sell_date'), class: 'text-center' },
                { label: this.$t('externalUserIrrigation.invoice_no'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                  { key: 'index' },
                  { key: 'dealer_name_bn' },
                  { key: 'dealer_code' },
                  { key: 'district_name_bn' },
                  { key: 'upazila_name_bn' },
                  { key: 'sell_date' },
                  { key: 'invoice_no' },
                  { key: 'action' }
                ]
            } else {
                keys = [
                  { key: 'index' },
                  { key: 'dealer_name' },
                  { key: 'dealer_code' },
                  { key: 'district_name' },
                  { key: 'upazila_name' },
                  { key: 'sell_date' },
                  { key: 'invoice_no' },
                  { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
      flatpickr('.date-picker', {})
        core.index()
    },
    watch: {
      'search.pre_division_id': function (newVal, oldVal) {
          this.districtList = this.getDistrictList(newVal)
      },
      'search.pre_district_id': function (newVal, oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
      },
      'search.pre_upazilla_id': function (newVal, oldVal) {
          this.dealerNameList = this.getDealerNameList(newVal)
      }
    },
    methods: {
      changeStatusCustom (baseUrl, uri, item) {
          Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.update'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        },
        toggleStatusCustom (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.deleteConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        toggleStatusCustom2 (baseUrl, uri, item) {
          window.vm.$swal({
            title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
            showCancelButton: true,
            confirmButtonText: window.vm.$t('globalTrans.yes'),
            cancelButtonText: window.vm.$t('globalTrans.no'),
            focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.changeStatusCustom(baseUrl, uri, item)
            }
          })
        },
        details (item) {
          this.items = item
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, saleEntryList, params).then(response => {
                if (response.success) {
                    // this.$store.dispatch('setList', response.data.data)
                    this.$store.dispatch('setList', this.getRelationalData(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataFilter (data) {
          return data.filter(item => item.status === 2)
        },
        getRelationalData(data) {
          const divisionList = this.$store.state.commonObj.divisionList
          const districtList = this.$store.state.commonObj.districtList
          const upazilaList = this.$store.state.commonObj.upazilaList
          const unionList = this.$store.state.commonObj.unionList
          const dealerBasicList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
          const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
          const listData = data.map(item => {
            const dealerBasicObj = dealerBasicList.find(data => data.value === item.dealer_id)
            const divisionObj = divisionList.find(data=> data.value === dealerBasicObj.per_division_id)
            const districtObj = districtList.find(data=> data.value === dealerBasicObj.per_district_id)
            const upazilaObj = upazilaList.find(data=> data.value === dealerBasicObj.per_upazilla_id)
            const unionObj = unionList.find(data=> data.value === dealerBasicObj.per_union_id)
            const fiscalYearObj = fiscalYears.find(doc => doc.value === item.fiscal_year_id)
            const fiscalYearData = {}
            if(typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const dealerBasicData = {}
            if(dealerBasicObj !== undefined) {
              dealerBasicData.dealer_name = dealerBasicObj.text_en
              dealerBasicData.dealer_name_bn = dealerBasicObj.text_bn
              dealerBasicData.division_name = divisionObj.text_en
              dealerBasicData.division_name_bn = divisionObj.text_bn
              dealerBasicData.district_name = districtObj.text_en
              dealerBasicData.district_name_bn = districtObj.text_bn
              dealerBasicData.upazila_name = upazilaObj.text_en
              dealerBasicData.upazila_name_bn = upazilaObj.text_bn
              dealerBasicData.union_name = unionObj.text_en
              dealerBasicData.union_name_bn = unionObj.text_bn
            } else {
              dealerBasicData.dealer_name = ''
              dealerBasicData.dealer_name_bn = ''
              dealerBasicData.division_name = ''
              dealerBasicData.division_name_bn = ''
              dealerBasicData.district_name = ''
              dealerBasicData.district_name_bn = ''
              dealerBasicData.upazila_name = ''
              dealerBasicData.upazila_name_bn = ''
              dealerBasicData.union_name = ''
              dealerBasicData.union_name_bn = ''
            }

            return Object.assign({}, item, fiscalYearData, dealerBasicData)
          })
          return listData
        },
        getDistrictList (divisionId) {
          const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
          if (divisionId) {
              this.districtList = districtList.filter(district => district.division_id === divisionId)
          }
          return this.districtList
        },
        getUpazilaList (districtId) {
          const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
          if (districtId) {
              this.upazilaList = upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return this.upazilaList
        },
        getDealerNameList (upazilaId) {
          const dealerData = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1 && item.pre_upazilla_id === upazilaId)
          const tempData = dealerData.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { text: item.text_bn, value: item.value }
            } else {
              return { text: item.text_en, value: item.value }
            }
          })
          return tempData
        }
    }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
