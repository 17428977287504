<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <b-form  @submit.prevent="handleSubmit(register)">
                    <b-row class="mt-2">
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Registration No" vid="registration_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="name"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.registration_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="text"
                                id="registration_no"
                                v-model="salesManage.registration_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Year Of Registration" vid="registration_year" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="registration_year"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.registration_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="salesManage.registration_year"
                                  :options="yearOfRegistration"
                                  id="registration_year"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="father_name"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.father_name_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="father_name"
                                v-model="salesManage.father_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                        <b-col lg="6"  sm="12">
                         <ValidationProvider name="Mother Name (En)" vid="mother_name" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mother_name"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.mother_name_en')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-input
                                id="mother_name"
                                v-model="salesManage.mother_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="contact_no"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('fertilizerConfig.contact_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="number"
                            id="tin_no"
                            v-model="salesManage.contact_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Permanent Address(En)" vid="per_address" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="per_address"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{$t('fertilizerConfig.address_details_en')}} <span class="text-danger">*</span>
                            </template>
                                <b-form-textarea
                                id="per_address"
                                v-model="salesManage.per_address"
                                rows="1"
                                max-rows="2"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Tin No" vid="tin_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="tin_no"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.tin_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="number"
                                id="tin_no"
                                v-model="salesManage.tin_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Bin No" vid="bin_no" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="bin_no"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{$t('fertilizerConfig.bin_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            type="number"
                            id="bin_no"
                            v-model="salesManage.bin_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="NID" vid="nid" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="nid"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('fertilizerConfig.nid')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="number"
                                  id="nid"
                                  v-model="salesManage.nid"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="contact_no"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{$t('fertilizerConfig.contact_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  type="number"
                                  id="tin_no"
                                  v-model="salesManage.contact_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="NID" vid="nid" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.fertilizer_type')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="salesManage.fertilizer_type_id"
                              :options="fertilizerTypeList"
                              id="fertilizer_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="NID" vid="nid" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.fertilizer_name_en')}} <span class="text-danger">*</span>
                                </template>
                               <b-form-select
                                plain
                                v-model="salesManage.fertilizer_id"
                                :options="fertilizerNameList"
                                id="fertilizer_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="NID" vid="nid" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('dealer_management.sale_quantity')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                          type="number"
                          id="nid"
                          v-model="salesManage.nid"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="contact_no"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('dealer_management.sale_quantity')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="salesManage.division_id"
                                :options="divisionList"
                                id="per_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="NID" vid="nid" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="nid"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('fertilizerConfig.name_of_district')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                          plain
                          v-model="salesManage.district_id"
                          :options="districtList"
                          id="pre_district_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6"  sm="12">
                        <ValidationProvider name="Contact No" vid="contact_no" rules="required|min:11|max:11">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="contact_no"
                                slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{$t('fertilizerConfig.name_of_upazilla')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="salesManage.upazilla_id"
                                :options="upazilaList"
                                id="per_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerSetupStore, dealerSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getSalesManage()
      this.salesManage = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      test: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      salesManage: {
        id: '',
        fertilizer_type_id: 0,
        fertilizer_id: 0,
        registration_year: 0,
        category: 0,
        registration_no: '',
        nid: '',
        tin_no: '',
        bin_no: '',
        contact_no: '',
        division_id: 0,
        district_id: 0
      },
      fertilizerNameList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      districtPerList: [],
      upazilaPerList: [],
      unionPerList: [],
      officeList: []
    }
  },
  computed: {
    fertilizerTypeList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerTypeList.filter(item => item.status === 1)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    divisionPerList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    yearOfRegistration: function () {
      const fiscalyear = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  watch: {
    'salesManage.fertilizer_type_id': function (newVal) {
      this.fertilizerNameList = this.getFertilizerList(newVal)
    },
    'salesManage.division_id': function (newVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'salesManage.district_id': function (newVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    getFertilizerList (fertilizerID = null) {
        const fertilizerList = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        if (fertilizerID) {
            return fertilizerList.filter(item => item.value === fertilizerID).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
        return fertilizerList
    },
    getSalesManage () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.salesManage).map(key => {
        if (key === 'pay_order_attachment') {
          formData.append(key, this.pay_order_attachment)
        }
        if (key === 'photo') {
          formData.append(key, this.photo)
        } else {
          formData.append(key, this.salesManage[key])
        }
      })

      if (this.salesManage.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, `${dealerSetupUpdate}/${this.id}`, formData, config)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, dealerSetupStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    }
  }
}
</script>
